@define-mixin link-icon {
  padding: 5px;
  height: 100%;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: var(--gray4);
  }

  i {
    margin: 0;
  }
}
