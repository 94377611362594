.base {
  padding: 80px 0;
  color: var(--purple6);
  text-align: center;

  > div {
    @mixin container;

    @media (--gte-tablet) and (--lt-desktop) {
      width: 98%;
    }

    > a {
      margin-top: 30px;
      display: block;
    }
  }

  ul {
    @mixin plain-list;

    @media (--gte-tablet) {
      display: flex;

      li {
        flex: 1;
      }
    }
  }
}

.header {
  text-align: center;
  color: var(--purple6);

  h2 {
    font-size: 22px;

    @media (--gt-phone) {
      font-size: 26px;
    }

    @media (--gte-tablet) {
      font-size: 36px;
    }
  }

  h3 {
    font-size: 20px;
    font-weight: 300;
    margin-bottom: 22px;
  }
}

.therapist {
  font-size: 18px;
  padding: 10px 0;

  @media (--gte-tablet) {
    padding: 4px;
  }

  @media (--gte-desktop) {
    padding: 14px;
  }

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }
}

.inner {
  display: flex;
  flex-direction: column;
  color: var(--body-font-color);
  background-color: var(--white);
  box-shadow: 0 1px 10px 0 rgba(83, 55, 71, 0.1);
  border-radius: 3px;
  width: 100%;
  margin: 0 auto;
  min-height: 100%;
  font-weight: 300;
  transition-property: box-shadow, transform;
  transition-duration: 0.15s;

  &:hover {
    box-shadow: var(--block-shadow-hover);
    transform: translateY(-2px);

    img {
      transform: translateY(-6px);
    }
  }

  > div:first-child {
    flex: 1;
  }
}

.photoRow {
  display: flex;
  margin: 0 0 10px;

  > div {
    flex: 1;
    padding-top: 25px;

    &:nth-child(2) {
      padding-top: 0;
      margin-top: -10px;
    }
  }

  img {
    border-radius: 100%;
    box-shadow: var(--floating-shadow);
    transition-property: transform;
    transition-duration: 0.5s;
  }
}

.rating {
  font-size: 18px;

  i {
    color: var(--orange);
    margin: 0;
  }
}

.name {
  font-size: 22px;
  line-height: 1.5rem;
  font-weight: 500;

  a {
    color: var(--purple6);
  }
}

.title {
  font-size: 13px;
  font-weight: 100;
  margin: 2px 0 14px;
  color: var(--gray8);
}

.locations {
  color: var(--gray8);
  font-weight: 100;
  font-size: 13px;
  display: flex;
  justify-content: center;
  margin-top: 8px;

  @media (--gte-largephone) {
    font-size: 14px;
  }

  @media (--gte-tablet) {
    font-size: 13px;
  }

  @media (--gte-desktop) {
    font-size: 14px;
  }

  > div {
    &:first-child {
      margin-right: 5px;
    }

    &:last-child {
      margin-left: 5px;
    }
  }

  i {
    width: 1.3em;
    height: 1.3em;
    margin-right: 3px;
    color: var(--gray8);
  }
}

.fees {
  font-size: 18px;
  color: var(--purple6);

  div {
    color: var(--gray6);
    font-size: 12px;
  }
}

.availability {
  padding: 8px;
  margin: 8px 0;
  font-size: 13px;
  border-top: 1px solid var(--gray3);
  border-bottom: 1px solid var(--gray3);
  background-color: var(--blue1);

  a {
    color: var(--blue7);
  }
}

.highlights {
  margin: 12px 20px 14px;
  font-size: 12px;
  text-align: left;

  mark {
    color: var(--body-font-color);
  }
}

.actionBar {
  padding: 10px 20px;
  background-color: var(--gray0);
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;

  > div {
    display: flex;
    align-items: center;
  }
}

.bookButton {
  @mixin button-mini;
}

.pickerLink {
  @mixin link-icon;

  i {
    --icon-size: 1.1em;
  }
}

.recommended {
  position: relative;

  > div {
    background: linear-gradient(90deg, #e1814b 0, #fd1d1d 50%, #833ab4 100%);
    border-radius: 4px;
    color: var(--white);
    font-size: 11px;
    font-weight: 500;
    padding: 3px 9px 2px;
    position: absolute;
    right: -6px;
    top: -10px;
    text-transform: uppercase;
    z-index: 1;

    @media (--lt-desktop) {
      right: 6px;
      top: -8px;
    }
  }
}

.bubbles {
  background-color: var(--blue2);

  @media (--gt-tablet) {
    background-image: svg-load('speech_bubble.svg'), svg-load('speech_bubble2.svg');
    background-repeat: no-repeat, no-repeat;
    background-position: -100px -50px, right 300px;
    background-size: 580px;
  }

  @media (--gt-largedesktop) {
    background-position: -50px -50px, right 200px;
    background-size: 750px;
  }
}

.loader {
  margin: 0 auto;
  padding: 80px 0;

  > div {
    @mixin container;

    > div:last-child {
      display: flex;
      height: 320px;
      padding: 15px 10px 0;

      @media (--gte-tablet) {
        grid-gap: 10px;
      }

      @media (--gte-desktop) {
        grid-gap: 25px;
      }

      > div {
        flex: 1;
        box-shadow: 0 1px 10px 0 rgb(83 55 71 / 10%);

        @media (--lt-tablet) {
          &:not(:first-child) {
            display: none;
          }
        }
      }
    }
  }
}

.loader_shimmer {
  @mixin bgShimmer;
  border-radius: 4px;
}
