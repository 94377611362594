@define-mixin bgShimmer {
  background: var(--gray3);
  background-image: linear-gradient(
    to right,
    var(--gray3) 0%,
    var(--gray2) 20%,
    var(--gray3) 40%,
    var(--gray3) 100%
  );
  background-size: 800px 100%;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeholderShimmer;
  animation-timing-function: linear;

  @keyframes placeholderShimmer {
    0% {
      background-position: -468px 0;
    }
    100% {
      background-position: 468px 0;
    }
  }
}
